/* index.css または App.css */
.image-item {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  /* src/App.css */
.demo-wrapper {
  max-width: 100%; /* 親要素の最大幅を100%に設定 */
  overflow: auto; /* 必要に応じてスクロールバーを表示 */
}

.demo-editor {
  max-width: 100%; /* エディターの最大幅を100%に設定 */
}